<template>
  <div class="common-list">
    <c-loading :loading="isLoading">

      <!-- TODO：无目的地点击门美一 -->
      <navigation-bar :type="13" :originCName="depCityName" @changeSearch="handleChangeArrive" @search="handleSearch"></navigation-bar>

      <div :style="{ height: px2rem(env.os === 'ios' ? 20 : 44) }"></div>

      <!-- 1 产品类型  2 跟团游类型  3 门票主题  4 跟团游主题 -->
      <category-list :autoJump="false" :JumpTo="handleCategoryJumpTo"></category-list>

      <div :style="{ height: px2rem(16) }"></div>

      <recommend-card label="跟团游" v-if="tourGroupList && tourGroupList.length" @more="JumpTo(CATEGORY['跟团游'])">
        <div v-for="(item, index) in tourGroupList" :key="index" @click="JumpToDetail(item)">
          <entity-item :data="item"></entity-item>
          <div v-if="index + 1 !== tourGroupList.length" :style="{ height: px2rem(17) }"></div>
        </div>
      </recommend-card>

      <div :style="{ height: px2rem(12) }"></div>

      <recommend-card label="酒景" v-if="hotelScenicList && hotelScenicList.length" @more="JumpTo(CATEGORY['酒景'])">
        <div v-for="(item, index) in hotelScenicList" :key="index" @click="JumpToDetail(item)">
          <entity-item :data="item"></entity-item>
          <div v-if="index + 1 !== hotelScenicList.length" :style="{ height: px2rem(17) }"></div>
        </div>
      </recommend-card>

      <div :style="{ height: px2rem(12) }"></div>

      <recommend-card label="门票" v-if="ticketList && ticketList.length" @more="JumpTo(CATEGORY['门票'])">
        <div class="flex flex-wrap flex-start">
          <template v-for="(item, index) in ticketList">
            <block-item :key="'block-item' + index" :data="item" :style="{ marginTop: index > 2 ? px2rem(20) : '' }" @click="JumpToDetail(item)">
            </block-item>
            <div v-if="(index + 1) % 3 !== 0" :key="'block-item-div' + index" :style="{ width: px2rem(8) }"></div>
          </template>
        </div>
      </recommend-card>

      <div class="home-indicator" style="height: 26px"></div>
    </c-loading>
  </div>
</template>

<script>
import get from "@/utils/get";
import { px2rem } from "./mixins";
import * as LocalData from "@/service/localData";
import { CATEGORY } from "@/service/enumerate";
import env from "@/utils/env";
import _ from "lodash";

import NavigationBar from "./components/navigation-bar";
import KindBar from "./components/kind-bar";
import CategoryList from "@/components/category/category-list";
import RecommendCard from "./components/recommend-card";
import BlockItem from "./components/block-item";
import EntityItem from "./components/entity-item";
import nativeApi from "@/utils/nativeApi";

import service from "@/service";

export default {
  mixins: [px2rem],
  components: {
    NavigationBar,
    KindBar,
    CategoryList,
    RecommendCard,
    BlockItem,
    EntityItem,
  },
  data() {
    return {
      // common
      isLoading: true,
      CATEGORY,

      // 产品垂类
      // dataList: [],
      // 热门推荐
      // hotTicketList: [],
      // 为你推荐列表
      // recommendList: [],

      depCityName: "",

      // 跟团游
      tourGroupList: [],
      // 酒景套餐
      hotelScenicList: [],
      // 门票
      ticketList: [],

      env,
    };
  },
  methods: {
    init() {
      this.depCityName = LocalData.getCity().cityName;
      this.getR215055({
        content: decodeURIComponent(_.get(this.$route, 'query.scenicSpotId', ''))?'':decodeURIComponent(_.get(this.$route, 'query.keyword', '')),
        scenicSpotId: decodeURIComponent(_.get(this.$route, 'query.scenicSpotId', ''))
      }).finally(() => (this.isLoading = false));
    },
    async getR215055({content = "", scenicSpotId = ''}) {
      const hasArr = (content !== '' || scenicSpotId !== '') ? false : true
      const res = await service.r215055({
        depCity: LocalData.getOriginCity().cityName,
        depCityCode: LocalData.getOriginCity().cityCode,
        arrCity: hasArr ? LocalData.getCity().cityName : void 0,
        arrCityCode: hasArr ? LocalData.getCity().cityCode : void 0,
        content: content || "",
        scenicSpotId: scenicSpotId || ''
      });
      const data = get(res, "res.bd.data.classify", []);
      const temp = data.reduce((a, b) => {
        if (b.category === CATEGORY["跟团游"]) {
          a.tourGroupList = b.productList || [];
          return a;
        }
        if (b.category === CATEGORY["酒景"]) {
          a.hotelScenicList = b.productList || [];
          return a;
        }
        if (b.category === CATEGORY["门票"]) {
          a.ticketList = b.productList || [];
          return a;
        }
        return a;
      }, {});
      this.tourGroupList = get(temp, "tourGroupList", []).map((item) => ({
        raw: item,
        img: item.img || "",
        type: get(item, "productTypeTag.name", ""),
        title: item.scenicSpotName || "",
        desc: item.description || "",
        productTags: (item.saleTags || []).map((item) => item.name).join("｜"),
        tags: (item.themeTag || []).map((tag) => ({
          text: tag.name,
          color: tag.colour,
        })),
        channelName: item.serviceProvider,
        price: item.salePrice || "--",
        pricePerfixed: item.pricePerfixed || "¥",
        priceSuffix: item.priceSuffix || "起",
        productId: item.productId || null,
      }));
      this.hotelScenicList = get(temp, "hotelScenicList", []).map((item) => ({
        raw: item,
        img: item.img || "",
        type: get(item, "productTypeTag.name", ""),
        title: item.scenicSpotName || "",
        desc: item.description || "",
        productTags: (item.saleTags || []).map((item) => item.name).join("｜"),
        tags: (item.themeTag || []).map((tag) => ({
          text: tag.name,
          color: tag.colour,
        })),
        channelName: item.serviceProvider,
        price: item.salePrice || "--",
        pricePerfixed: item.pricePerfixed || "¥",
        priceSuffix: item.priceSuffix || "起",
      }));
      this.ticketList = get(temp, "ticketList", []).map((item, i) => ({
        raw: item,
        index: i,
        img: item.img || "",
        name: item.productName || "",
        price: item.salePrice || "--",
        pricePerfixed: item.pricePerfixed || "¥",
        priceSuffix: item.priceSuffix || "起",
      }));
    },
    handleCategoryJumpTo(item) {
      const query = {
        // 出发地
        cityName: LocalData.getOriginCity().cityName,
        cityCode: LocalData.getOriginCity().cityCode,
        loadArrive: true,
      };

      const page = {
        url: "/",
        query,
      };

      switch (item.code) {
        case CATEGORY["跟团游"]:
          page.url = "/tour-group";
          page.name = "tour-group";
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic-list";
          page.name = "hotel-scenic-list";
          break;
        case CATEGORY["门票"]:
          page.url = "/list";
          page.name = "list";
          break;
      }

      // this.$router.push(page)
      this.$page.push(page);
    },
    JumpTo(position) {
      const query = {
        // 出发地
        cityName: LocalData.getOriginCity().cityName,
        cityCode: LocalData.getOriginCity().cityCode,
      };
      const page = {
        url: "/",
        query,
      };

      switch (position) {
        case CATEGORY["门票"]:
          page.url = "/list";
          page.name = "list";
          break;
        case CATEGORY["跟团游"]:
          page.url = "/tour-group";
          page.name = "tour-group";
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic-list";
          page.name = "hotel-scenic-list";
          break;
      }

      // this.$router.push(page)
      this.$page.push(page);
    },
    handleChangeKind(kind) {
      console.log(kind);
    },
    handleChangeArrive(arrive) {
      console.log("------ handleChangeArrive ------\n");
      console.log(arrive);
      this.getR215055({});
    },
    handleSearch(content) {
      console.log("------ handleSearch ------\n");
      console.log(content);
      this.getR215055({
        content: content.scenicSpotId ? '' : content.value,
        scenicSpotId: content.scenicSpotId || ''
      });
    },
    JumpToDetail(item) {
      console.log("------ JumpToDetail ------", item.raw);

      const page = {
        url: "/",
        query: {
          analyseSourceEntry: env.hlsource || "",
        },
      };

      switch (item.raw.category) {
        case CATEGORY["门票"]:
          page.url = "/scenic-detail";
          page.name = "scenic-detail";
          page.query.scenicSpotId = item.raw.scenicSpotId;
          break;
        case CATEGORY["跟团游"]:
          page.url = "/tour-group-detail";
          page.name = "tour-group-detail";
          page.query.productId = item.raw.productId;
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic";
          page.name = "hotel-scenic";
          page.query.productId = item.raw.productId;
          break;
      }

      // this.$router.push(page)
      this.$page.push(page);
    },
  },
  created() {
    this.init();
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "1" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/reset.scss";
@import "@/scss/style.scss";
@import "./common.scss";

.common-list {
  background: linear-gradient(180deg, #ffffff, #f2f4f7 9%);
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
